import React from "react";

import rotate from "../../images/mircorec-app/rotate.svg";
import settings from "../../images/mircorec-app/settings.svg";
import mirror from "../../images/mircorec-app/mirror.svg";
import phone from "../../images/mircorec-app/phone.svg";
import focus from "../../images/mircorec-app/focus.svg";
import brightness from "../../images/mircorec-app/brightness.svg";
import whiteBalance from "../../images/mircorec-app/whiteBalance.svg";

import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";

const Recordings = () => {
  const text = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Container style={{ padding: "20px", marginTop: "5em" }} maxWidth="lg">
      <div style={text}>
        <h2
          style={{
            marginTop: "0",
            marginBottom: "2em",
            width: "85%",
            textAlign: "center",
          }}
        >
          The all-in-one application for your microscopic data
        </h2>
      </div>
      <Grid
        container
        justifyContent={{ sm: "center", md: "space-between" }}
        style={{
          alignItems: "center",
          padding: "20px",
        }}
      >
        <Grid item container md={4} sm={10} order={{ sm: 1, xs: 1 }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "3em",
              alignItems: "center",
            }}
          >
            <img src={rotate}></img>
            <div
              style={{
                fontSize: "25px",
                marginLeft: "0.5em",
                fontWeight: "600",
              }}
            >
              Rotate images and videos by 90 degrees
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "3em",
              alignItems: "center",
            }}
          >
            <img src={settings}></img>
            <div
              style={{
                fontSize: "25px",
                marginLeft: "0.5em",
                fontWeight: "600",
              }}
            >
              Control the quality and sound setting
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={mirror}></img>
            <div
              style={{
                fontSize: "25px",
                marginLeft: "0.5em",
                fontWeight: "600",
              }}
            >
              Mirror images and videos
            </div>
          </div>
        </Grid>
        <Grid
          item
          container
          md={4}
          sm={10}
          order={{ sm: 2, xs: 2 }}
          style={{ justifyContent: "center" }}
        >
          <img src={phone} style={{ width: "80%" }}></img>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={10}
          md={4}
          order={{ xs: 3, sm: 3 }}
          justifyContent={{ xs: "center", sm: "flex-start", md: "flex-end" }}
          style={{ height: "400px" }}
        >
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            style={{
              alignItems: "center",
            }}
          >
            <Grid
              item
              container
              xs={9}
              sm={10}
              md={9}
              order={{ xs: 2, sm: 2, md: 1 }}
              style={{ width: "auto" }}
              justifyContent={{
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-end",
              }}
              textAlign={{ xs: "left", sm: "left", md: "right" }}
            >
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "600",
                  marginRight: "0.5em",
                  marginLeft: "0.5em",
                }}
              >
                Microscopic focus control
              </div>
            </Grid>
            <Grid
              item
              container
              xs={3}
              sm={2}
              md={3}
              order={{ xs: 1, sm: 1, md: 2 }}
              style={{ width: "auto" }}
            >
              <img src={focus} alt="focus"></img>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            style={{
              alignItems: "center",
            }}
          >
            <Grid
              item
              container
              xs={9}
              sm={10}
              md={9}
              order={{ xs: 2, sm: 2, md: 1 }}
              style={{ width: "auto" }}
              justifyContent={{
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-end",
              }}
              textAlign={{ xs: "left", sm: "left", md: "right" }}
            >
              <div
                style={{
                  fontSize: "25px",
                  marginRight: "0.5em",
                  marginLeft: "0.5em",
                  fontWeight: "600",
                }}
              >
                Brightness control
              </div>
            </Grid>
            <Grid
              item
              container
              xs={3}
              sm={2}
              md={3}
              order={{ xs: 1, sm: 1, md: 2 }}
              style={{ width: "auto" }}
            >
              <img src={brightness} alt="brightness"></img>
            </Grid>
          </Grid>

          <Grid
            item
            container
            xs={12}
            sm={12}
            md={12}
            style={{
              alignItems: "center",
              marginBottom: "3em",
            }}
          >
            <Grid
              item
              container
              xs={9}
              sm={10}
              md={9}
              order={{ xs: 2, sm: 2, md: 1 }}
              justifyContent={{
                xs: "flex-start",
                sm: "flex-start",
                md: "flex-end",
              }}
              style={{ width: "auto" }}
              textAlign={{ xs: "left", sm: "left", md: "right" }}
            >
              <div
                style={{
                  fontSize: "25px",
                  marginRight: "0.5em",
                  marginLeft: "0.5em",
                  fontWeight: "600",
                }}
              >
                White balance control
              </div>
            </Grid>
            <Grid
              item
              container
              xs={3}
              sm={2}
              md={3}
              order={{ xs: 1, sm: 1, md: 2 }}
              style={{ width: "auto" }}
            >
              <img src={whiteBalance} alt="whiteBalance"></img>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Recordings;

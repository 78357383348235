import React from "react";

import GooglePlay from "/src/images/GooglePlay.png";
import AppStore from "/src/Images/AppStore.png";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

const Download = () => {
  return (
    <Container xs={12} maxWidth={"lg"}>
      <h2 style={{ textAlign: "center", marginBottom: "8%" }}>
        Download the MicroREC App
      </h2>
      <Grid container xs={12} style={{ justifyContent: "center" }}>
        <Grid item sm={5} xs={10}>
          <a
            href="https://play.google.com/store/apps/details?id=com.app_microrec&gl=AT"
            style={{ display: "flex", justifyContent: "space-around" }}
            target="_blank"
          >
            <button
              href="https://play.google.com/store/apps/details?id=com.app_microrec&gl=AT"
              className="download_button"
            >
              <img
                src={GooglePlay}
                style={{ width: "30px", marginRight: "1em" }}
              ></img>
              <div className="download_text">
                <div style={{ fontSize: "10px" }}>GET&nbsp;IT&nbsp;ON</div>
                <div style={{ fontWeight: "700" }}>Google&nbsp;Play</div>
              </div>
            </button>
          </a>
        </Grid>
        <Grid item sm={5} xs={10} marginTop={{ xs: 3, sm: 0 }}>
          <form
            action="https://apps.apple.com/app/microrec/id1524787428"
            style={{ display: "flex", justifyContent: "space-around" }}
            target="_blank"
          >
            <button type="submit" className="download_button">
              <img
                src={AppStore}
                style={{ width: "30px", marginRight: "1em" }}
              ></img>
              <div className="download_text">
                <div style={{ fontSize: "10px" }}>GET&nbsp;IT&nbsp;ON</div>
                <div style={{ fontWeight: "700" }}>App&nbsp;Store</div>
              </div>
            </button>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Download;

import * as React from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Recordings from "../components/microrec-app/recordings";
import YoutubeGallery from "../components/microrec-app/gallery";
import Download from "../components/microrec-app/download";
import Seo from "../components/layout/seo";
import logo from "/src/images/iso_color.png";
import GooglePlay_white from "/src/components/microrec-app/GooglePlay_white.png";
import AppStore_white from "/src/components/microrec-app/AppStore_white.png";
import Grid from "@mui/material/Grid";
import Subscribe from "../components/misc/check_list";
import Connect from "../components/microrec-app/connect";
import Pro from "../components/microrec-app/pro";
import Sessions from "../components/microrec-app/sessions";
import video from "../images/gifs/microrecApp.mp4";
import preLoader from "../images/gifs/microrecApp.png";

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = <img style={{ width: "50px" }} src={logo} alt="Logo" />;

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title="Digitize your microscope or slit lamp - MicroREC app | Custom Surgical"
        description="Record, optimize, organize, and share your diagnosis or procedures from a slit lamp or microscope in a few clicks."
      />{" "}
      <Subscribe />
      <>
        <Grid container xs={12} className="over_microrec">
          <video
            muted="true"
            autoplay="autoplay"
            loop="loop"
            id="microrec_main_video"
            poster={preLoader}
          >
            <source src={video} type="video/mp4" />
            <img
              src={preLoader}
              title="Your browser does not support the <video> tag"
            />
          </video>
          <h1 className="text_over_microrec">
            MICROREC APP<div></div>
            <Grid item xs={10} sm={7}>
              <div
                style={{
                  fontSize: "30px",
                  marginTop: "1em",
                  color: "white",
                  fontWeight: "500",
                }}
              >
                <b>Free App</b> to improve your recordings and organize them
              </div>
            </Grid>
            <Grid container xs={12} marginTop={{ xs: 7 }}>
              <Grid item sm={4} xs={6} style={{ marginRight: "1em" }}>
                <a
                  href="https://play.google.com/store/apps/details?id=com.app_microrec&gl=AT"
                  target="_blank"
                >
                  <button
                    type="submit"
                    className="download_button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      border: "solid 1px white",
                    }}
                  >
                    <img
                      src={GooglePlay_white}
                      style={{ width: "35px", marginRight: "0.5em" }}
                    ></img>
                    <div className="download_text">
                      <div style={{ fontSize: "12px" }}>
                        GET&nbsp;IT&nbsp;ON
                      </div>
                      <div style={{ fontWeight: "700", fontSize: "20px" }}>
                        Google&nbsp;Play
                      </div>
                    </div>
                  </button>
                </a>
              </Grid>
              <Grid item sm={4} xs={6} marginTop={{ xs: 3, sm: 0 }}>
                <form
                  action="https://apps.apple.com/app/microrec/id1524787428"
                  target="_blank"
                >
                  <button
                    type="submit"
                    className="download_button"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      color: "white",
                      border: "solid 1px white",
                    }}
                  >
                    <img
                      src={AppStore_white}
                      style={{ width: "30px", marginRight: "0.5em" }}
                    ></img>
                    <div className="download_text">
                      <div style={{ fontSize: "12px" }}>
                        GET&nbsp;IT&nbsp;ON
                      </div>
                      <div style={{ fontWeight: "700", fontSize: "20px" }}>
                        App&nbsp;Store
                      </div>
                    </div>
                  </button>
                </form>
              </Grid>
            </Grid>
          </h1>
        </Grid>
        <Recordings />
        <div style={{ marginBottom: "10%" }}></div>
        <Sessions />
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} margin={0}>
            <YoutubeGallery />
          </Grid>
        </Grid>
        <div style={{ marginBottom: "10%" }} id="Download"></div>
        <Connect />
        <div style={{ marginBottom: "10%" }}></div>
        {/* <Pro /> */}
        {/* <div style={{ marginBottom: "10%" }}></div> */}
        <Download />
        <div style={{ marginBottom: "15%" }}></div>
      </>{" "}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

import React from "react";
import ReactPlayer from "react-player";
import { Carousel } from "react-responsive-carousel";
import "../../static/carousel.css"; // requires a loader
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Container } from "@mui/system";

const YoutubeSlide = ({ url, isSelected }) => (
  <ReactPlayer width="100%" url={url} playing={isSelected} muted />
);

const YoutubeGallery = () => {
  const customRenderItem = (item, props) => (
    <item.type {...item.props} {...props} />
  );

  const getVideoThumb = (videoId) =>
    `https://img.youtube.com/vi/${videoId}/default.jpg`;

  const getVideoId = (url) =>
    url.substr("https://www.youtube.com/embed/".length, url.length);

  const customRenderThumb = (children) =>
    children.map((item) => {
      const videoId = getVideoId(item.props.url);
      return <img src={getVideoThumb(videoId)} />;
    });

  return (
    <>
      <Container maxWidth="lg">
        <div style={{ textAlign: "center" }}>
          <h2
            style={{
              textAlign: "center",
              width: "70%",
              marginLeft: "auto",
              marginRight: "auto",
              marginBottom: "2em",
            }}
          >
            Surgeries recorded with the MicroREC App
          </h2>
        </div>
        <Carousel
          axis={"vertical"}
          renderItem={customRenderItem}
          renderThumbs={customRenderThumb}
        >
          <YoutubeSlide
            key="youtube-2"
            url="https://www.youtube.com/embed/3GIgs6JtzLA"
          />
          <YoutubeSlide
            key="youtube-1"
            url="https://www.youtube.com/embed/QIgVxj8AbYA"
          />
          <YoutubeSlide
            key="youtube-3"
            url="https://www.youtube.com/embed/wDogyz4Qda4"
          />
          <YoutubeSlide
            key="youtube-4"
            url="https://www.youtube.com/embed/a8cE-Qjcje0"
          />
          <YoutubeSlide
            key="youtube-5"
            url="https://www.youtube.com/embed/3ZVCB0EvbJw"
          />
          <YoutubeSlide
            key="youtube-6"
            url="https://www.youtube.com/embed/J_0E2rcS1Yw"
          />
        </Carousel>
        <a
          href="https://www.youtube.com/c/CustomSurgical"
          style={{ display: "flex", justifyContent: "space-around" }}
          id="Carousel"
        >
          <div class="btnsub">
            <YouTubeIcon />
            &nbsp; Subscribe
          </div>{" "}
        </a>
      </Container>
    </>
  );
};

export default YoutubeGallery;

import React from "react";
import one from "../../images/mircorec-app/pro/1.svg";
import two from "../../images/mircorec-app/pro/2.svg";
import three from "../../images/mircorec-app/pro/3.svg";
import four from "../../images/mircorec-app/pro/4.svg";
import five from "../../images/mircorec-app/pro/5.svg";
import six from "../../images/mircorec-app/pro/6.svg";
import gif from "../../images/mircorec-app/Pro-Edition-Gif.gif";
import { Container, Grid } from "@mui/material";

const Pro = () => {
  return (
    <>
      <Container maxWidth="lg">
        <Grid
          item
          container
          xs={12}
          style={{
            justifyContent: "space-between",
            alignItems: "center",
            padding: "20px",
          }}
        >
          <Grid
            item
            xs={12}
            marginBottom={{ md: "0", sm: "2em", xs: "3em" }}
            style={{
              display: "flex",
              flexDirection: "column",
            }}
            alignItems={{ md: "flex-end", sm: "flex-start", xs: "flex-start" }}
          >
            <b style={{ fontSize: "20px" }}>MicroREC Connect</b>
            <h2 style={{ marginTop: "0.5em" }}>MicroREC video edition</h2>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "3em",
              }}
            >
              <img
                src={one}
                style={{ width: "60px", marginRight: "0.5em" }}
              ></img>
              <div>
                <div style={{ fontWeight: "600" }}>Trim</div>
                <div>Remove any unnecessary footage.</div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "3em",
              }}
            >
              <img
                src={two}
                style={{ width: "60px", marginRight: "0.5em" }}
              ></img>
              <div>
                <div style={{ fontWeight: "600" }}>Rotate & Flip</div>
                <div>Change the orientation of a video.</div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "3em",
              }}
            >
              <img
                src={three}
                style={{ width: "60px", marginRight: "0.5em" }}
              ></img>
              <div>
                <div style={{ fontWeight: "600" }}>Crop</div>
                <div>
                  Remove unwanted edges to keep the focus where you want it.
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "3em",
              }}
            >
              <img
                src={four}
                style={{ width: "60px", marginRight: "0.5em" }}
              ></img>
              <div>
                <div style={{ fontWeight: "600" }}>Contrast Adjustement</div>
                <div>Improve the clarity and visibility of image details.</div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "3em",
              }}
            >
              <img
                src={five}
                style={{ width: "60px", marginRight: "0.5em" }}
              ></img>
              <div>
                <div style={{ fontWeight: "600" }}>Brigthness Control</div>
                <div>
                  Adjust the lighting of a video when it's too light or too
                  dark.
                </div>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "3em",
              }}
            >
              <img
                src={six}
                style={{ width: "60px", marginRight: "0.5em" }}
              ></img>
              <div>
                <div style={{ fontWeight: "600" }}>Brush</div>
                <div>Draw over the video, just by touching the screen.</div>
              </div>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <img src={gif} style={{ maxWidth: "100%" }}></img>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "10%",
            marginBottom: "10%",
          }}
        >
          <button className="shop">
            <a
              href="http://onelink.to/nqua9j"
              target="_blank"
              style={{ color: "white" }}
            >
              DOWNLOAD THE APP
            </a>
          </button>
        </div>
      </Container>
    </>
  );
};

export default Pro;
